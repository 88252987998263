import { trackEvent } from '@centered/analytics'
import ReactPlayer from 'react-player'

export default function VideoWalkthrough(): JSX.Element {
  return (
    <div className="max-w-5xl mx-auto">
      <h2 className="font-bold text-center text-2xl md:text-4xl mb-10">
        Want to see it?
      </h2>

      <style>{`
          .player-wrapper {
            position: relative;
            padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
          }

          .react-player {
            position: absolute;
            top: 0;
            left: 0;
          }

          .react-player > div {
            position: absolute; // Scaling will occur since parent is relative now
          }
        `}</style>

      <div
        onClick={() => {
          trackEvent('Played on home page video', {})
        }}
        className="player-wrapper rounded-xl overflow-hidden"
      >
        <ReactPlayer
          url="https://youtu.be/kCwhQC3K2I8"
          width="100%"
          height="100%"
          className="react-player"
          controls
        />
      </div>
    </div>
  )
}
