"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function SlackLogo(_a) {
    var className = _a.className;
    return (React.createElement("div", { className: className },
        React.createElement("svg", { "aria-label": "Slack logo", role: "img", viewBox: "0 0 40 40", width: "1em", height: "1em", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M8.40381 25.277C8.40381 27.5897 6.51458 29.4789 4.20191 29.4789C1.88923 29.4789 0 27.5897 0 25.277C0 22.9643 1.88923 21.0751 4.20191 21.0751L8.40381 21.0751V25.277Z", fill: "#E01E5A" }),
            React.createElement("path", { d: "M10.5211 25.2765C10.5211 22.9638 12.4103 21.0746 14.723 21.0746C17.0357 21.0746 18.9249 22.9638 18.9249 25.2765V35.7975C18.9249 38.1102 17.0357 39.9994 14.723 39.9994C12.4103 39.9994 10.5211 38.1102 10.5211 35.7975L10.5211 25.2765Z", fill: "#E01E5A" }),
            React.createElement("path", { d: "M14.7229 8.40381C12.4102 8.40381 10.521 6.51458 10.521 4.20191C10.521 1.88923 12.4102 0 14.7229 0C17.0356 0 18.9248 1.88923 18.9248 4.20191V8.40381L14.7229 8.40381Z", fill: "#36C5F0" }),
            React.createElement("path", { d: "M14.723 10.521C17.0356 10.521 18.9249 12.4102 18.9249 14.7229C18.9249 17.0356 17.0356 18.9248 14.723 18.9248L4.20191 18.9248C1.88923 18.9248 0 17.0356 0 14.7229C0 12.4102 1.88923 10.521 4.20191 10.521L14.723 10.521Z", fill: "#36C5F0" }),
            React.createElement("path", { d: "M31.5957 14.7231C31.5957 12.4105 33.4849 10.5212 35.7976 10.5212C38.1103 10.5212 39.9995 12.4105 39.9995 14.7231C39.9995 17.0358 38.1103 18.925 35.7976 18.925H31.5957L31.5957 14.7231Z", fill: "#2EB67D" }),
            React.createElement("path", { d: "M29.4786 14.723C29.4786 17.0356 27.5894 18.9249 25.2767 18.9249C22.9641 18.9249 21.0748 17.0356 21.0748 14.723L21.0748 4.20191C21.0748 1.88923 22.9641 0 25.2767 0C27.5894 0 29.4786 1.88923 29.4786 4.20191V14.723Z", fill: "#2EB67D" }),
            React.createElement("path", { d: "M25.2765 31.5957C27.5892 31.5957 29.4784 33.4849 29.4784 35.7976C29.4784 38.1103 27.5892 39.9995 25.2765 39.9995C22.9638 39.9995 21.0746 38.1103 21.0746 35.7976V31.5957L25.2765 31.5957Z", fill: "#ECB22E" }),
            React.createElement("path", { d: "M25.2771 29.4784C22.9644 29.4784 21.0752 27.5892 21.0752 25.2765C21.0752 22.9638 22.9644 21.0746 25.2771 21.0746L35.7981 21.0746C38.1108 21.0746 40.0001 22.9638 40.0001 25.2765C40.0001 27.5892 38.1108 29.4784 35.7981 29.4784L25.2771 29.4784Z", fill: "#ECB22E" }))));
}
exports.default = SlackLogo;
