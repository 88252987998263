"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsMobile = void 0;
var helpers_1 = require("@centered/helpers");
var react_1 = require("react");
var useIsMobile = function () {
    var _a = (0, react_1.useState)(false), isMobile = _a[0], setIsMobile = _a[1];
    (0, react_1.useEffect)(function () {
        setIsMobile((0, helpers_1.checkIsMobile)());
    }, []);
    return isMobile;
};
exports.useIsMobile = useIsMobile;
