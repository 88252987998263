import * as React from 'react'
import ActiveLink from './ActiveLink'
import Wrapper from './Wrapper'

const LINKS = [
  {
    label: 'Science of Flow',
    href: '/what-is-flow',
  },
  {
    label: 'Blog',
    href: '/blog',
  },
  {
    label: 'Contact Us',
    href: 'mailto:Support@TheSukha.co',
  },
]

export default function NewFooter(): JSX.Element {
  return (
    <footer className="pb-32 pt-8 md:pb-8 footer">
      <style jsx>{`
        .footer {
          background-color: #000000;
        }
      `}</style>

      <Wrapper>
        <div className="flex flex-col gap-4 md:flex-row md:divide-y-0 md:justify-start">
          {LINKS.map((link) => (
            <p key={link.href}>
              <NavLink text={link.label} href={link.href} />
            </p>
          ))}
        </div>

        <p className="text-white mt-10">© 2024 The Sukha Company</p>
      </Wrapper>
    </footer>
  )
}

function NavLink({ href, text }: { href: string; text: string }): JSX.Element {
  return (
    <ActiveLink
      href={href}
      text={text}
      className="block text-white hover:text-opacity-70 transition-colors"
      activeClassName=""
    />
  )
}
