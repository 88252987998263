import { SubscriptionPlans } from '@centered/firebase'
import { useSessionStorage } from 'react-use'

export function usePromptToUpgradeAfterSignup(): {
  planToPrompt: SubscriptionPlans | null
  setPlanToPrompt: (plan: SubscriptionPlans | null) => void
} {
  const [planToPrompt, setPromptToUpgradeAfterSignup] =
    useSessionStorage<SubscriptionPlans | null>(
      'promptToUpgradeAfterSignup',
      null,
    )

  return {
    planToPrompt,
    setPlanToPrompt: setPromptToUpgradeAfterSignup,
  }
}
