import { trackEvent } from "@centered/analytics"
import Image from "next/image"
import { useRef } from "react"
import GoogleAuthCta from "../shared/GoogleAuthCta"
import Wrapper from "../shared/Wrapper"
import AppPreviewImage from "./assets/Sukha-full-preview.png"
import AuthPageImage from "./assets/auth_page.png"
import ProductHuntLogo from "./assets/ProductHuntLogo1.png"
import HackerNewsLogo from "./assets/HackerNewsLogo.png"
import { useIsMobile } from "@centered/components/dist/hooks/useIsMobile"

export default function HeroSection(): JSX.Element {
  return (
    <Wrapper
      style={{
        minHeight: "15vh",
      }}
    >
      <div className='hero flex flex-col mb-6 md:flex-row md:items-center'>
        <div className='md:w-1/2 flex-shrink-0'>
          <h1>
            {/* <div
              className="font-bold text-3xl text-v2-gray-lighter mb-4"
              style={{
                textDecoration: 'line-through',
              }}
            >
              Procrastination. Stress.
            </div> */}

            <div className='text-4xl font-bold mb-8 md:mr-8 leading-snug'>
              Work with friends
            </div>
          </h1>

          <p className='text-2xl md:mr-8 leading-normal mb-6'>
            Listen to focus music and see people around the world while you
            work.
            <br />
            <br />
            Overcome procrastination, distraction and burnout together.
            <br />
            <br />
            <b>Finish your work faster and happier.</b>
            <br />
          </p>
          <GoogleAuthCta
            source={"v5CTAHero"}
            noGoogleAccountSource={"v5CTAHeroNonGoogle"}
          />
          <p className='text-xl leading-normal md:mr-8 mb-8 mt-10'>
            <i>Claim your 14-Day FREE Trial - no credit card required.</i>
          </p>
        </div>

        <AppPreview />
      </div>

      {/* As seen on section */}
      <AsSeenOnSection />
    </Wrapper>
  )
}

export function AppPreview(): JSX.Element {
  return (
    <>
      <style jsx>{`
        .preview-image {
          width: 550px !important;
          aspect-ratio: 1.8;
        }

        @media (min-width: 700px) {
          .preview-image {
            width: 800px !important;
          }
        }
      `}</style>

      <div className='w-1/2 overflow-hidden flex-shrink-0 preview-image relative transform -translate-x-10 md:translate-x-0'>
        <Image
          src={AppPreviewImage}
          alt='App Preview'
          width={800}
          height={774}
          priority
          className='object-contain object-center'
        />
      </div>
    </>
  )
}

export function SigninHero(): JSX.Element {
  const isMobile = useIsMobile()

  return (
    <>
      <style jsx>{`
        .preview-image {
          width: 550px !important;
          aspect-ratio: 800 / 800;
        }

        @media (min-width: 768px) {
          .preview-image {
            width: 800px !important;
          }
        }
      `}</style>

      <div
        className={`'w-1/2 overflow-hidden flex-shrink-0 preview-image relative transform -translate-x-10 md:translate-x-0 cursor-pointer'${
          isMobile ? " pt-4 px-6" : ""
        }`}
      >
        <Image
          src={AuthPageImage}
          alt='Sukha'
          width={isMobile ? 350 : 800}
          height={isMobile ? 350 : 800}
          priority
          className='object-contain object-center'
        />
      </div>
    </>
  )
}

export function AsSeenOnSection(): JSX.Element {
  return (
    <div className='flex flex-col items-center mt-8 mb-8'>
      <div className='flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 md:space-x-8'>
        <p className='text-xl font-bold'>
          <em>As seen on</em>
        </p>
        <div className='flex-shrink-0 border border-white rounded-lg p-1'>
          <a
            href='https://www.producthunt.com/products/sukha#sukha'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Image
              src={ProductHuntLogo}
              alt='Product Hunt'
              width={200}
              height={50}
              className='rounded-lg'
            />
          </a>
        </div>
        <div className='flex-shrink-0'>
          <Image
            src={HackerNewsLogo}
            alt='Hacker News'
            width={200}
            height={50}
            className='rounded-md'
          />
        </div>
      </div>
    </div>
  )
}
