import { trackEvent } from '@centered/analytics'
import Switch from '@centered/components/dist/Switch'
import { SubscriptionPlans } from '@centered/firebase'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faMortarBoard } from '@fortawesome/pro-solid-svg-icons/faMortarBoard'
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons/faRocketLaunch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useState } from 'react'
import { usePromptToUpgradeAfterSignup } from '../../hooks/usePromptToUpgradeAfterSignup'
import Wrapper from '../shared/Wrapper'

const FREE_LIST = [
  'AI–powered Productivity Coaches',
  'Science-Backed Flow Music',
  'Streamlined Task Management',
  'Session Reminders w/Calendar Integration',
  'Personalized Stats & Analytics',
  'Five 1:1 Buddy Sessions per month',
]

const PREMIUM_LIST = [
  'AI–powered Productivity Coaches',
  '1000+ hours of Flow Music',
  'Full Spotify Integration',
  'Notification Blocking',
  'Unlimited Task Management',
  'Pomodoro Timers',
  'Meeting Notifications',
  'Linear, Todoist, Asana integrations',
]

export default function Pricing(): JSX.Element {
  return (
    <Wrapper className="flex flex-col md:flex-row justify-between items-center gap-8">
      <div className="flex-1">
        <h2 className="text-2xl lg:text-4xl lg:leading-snug font-bold mb-6">
          I'm ready to get my life back.
        </h2>

        <p className="text-lg text-white text-opacity-80">
          100% Happiness Guarantee. <br />
          If you don't love The Sukha, we'll refund you. No questions asked.
        </p>
      </div>
      <PriceCard plan="pro" />
    </Wrapper>
  )
}

interface PriceCardProps {
  plan: 'free' | 'pro'
}

export function PriceCard({ plan }: PriceCardProps): JSX.Element {
  const { setPlanToPrompt } = usePromptToUpgradeAfterSignup()
  const [unitOfTime, setUnitOfTime] = useState<'month' | 'year'>('year')

  return (
    <div className="bg-white bg-opacity-20 relative rounded-md overflow-hidden p-4 flex-1 flex-shrink-0">
      {/* top border */}
      <div
        className={`h-2 absolute top-0 left-0 right-0 w-full ${
          plan === 'free'
            ? 'bg-white bg-opacity-60'
            : 'bg-gradient-to-r from-v5-pink to-v5-orange'
        }`}
      />
      {/* Pill */}
      <div
        className={`mt-2 inline-flex gap-1 rounded-full text-sm px-3 py-0.5 items-center  ${
          plan === 'free' ? 'bg-white bg-opacity-20' : 'bg-white text-v2-accent'
        }`}
      >
        <span>{plan === 'free' ? 'Free' : 'PRO Plan'}</span>
        {plan === 'pro' && <FontAwesomeIcon icon={faRocketLaunch} />}
      </div>
      {/* title */}
      <header className="my-4">
        {plan === 'free' ? (
          <div className="flex gap-2 items-center text-3xl">
            <div className="font-semibold">Free Plan</div>
          </div>
        ) : (
          <PremiumPriceTag
            unitOfTime={unitOfTime}
            onUnitChange={setUnitOfTime}
          />
        )}
      </header>
      <p className="text-sm font-light italic my-4">
        {plan === 'free'
          ? 'We support those bettering themselves and respect their limited budgets.'
          : 'For working professionals looking to supercharge their effectiveness and reduce their stress.'}
      </p>
      <div className="mb-8">
        {plan === 'pro' && (
          <p className="font-semibold mb-2">All the things:</p>
        )}

        <ul className="space-y-1">
          {(plan === 'free' ? FREE_LIST : PREMIUM_LIST).map((item, i) => (
            <FeatureItem text={item} key={`free-list-${i}`} />
          ))}
        </ul>
      </div>

      {/* Per Steven - commenting out Become a Pro button for now - mc */}

      {/* <Link
        href="/auth/login?redirect=/checkout"
        className={`block w-full text-center py-2 rounded-full font-semibold hover:opacity-80 transition-opacity ${
          plan === 'free'
            ? 'bg-white bg-opacity-50 text-black'
            : 'bg-gradient-to-r from-v5-pink to-v5-orange'
        }`}
        onClick={() => {
          if (plan === 'pro') {
            trackEvent('HomePageCTAClicked', {
              ctaSource: 'pro pricing',
            })
            setPlanToPrompt(
              unitOfTime === 'year'
                ? SubscriptionPlans.Yearly
                : SubscriptionPlans.Monthly,
            )
          } else {
            setPlanToPrompt(null)
            trackEvent('HomePageCTAClicked', {
              ctaSource: 'free pricing',
            })
          }
        }}
      >
        {plan === 'free' ? 'Continue' : 'Become a PRO'}
      </Link> */}
    </div>
  )
}

function FeatureItem({ text }: { text: string }): JSX.Element {
  return (
    <li className="flex gap-2 font-semibold items-center text-sm">
      <FontAwesomeIcon icon={faCheckCircle} />
      <p>{text}</p>
    </li>
  )
}

interface PremiumPriceTagProps {
  unitOfTime: 'month' | 'year'
  onUnitChange: (unitOfTime: 'month' | 'year') => void
}
{/*
function PremiumPriceTag({
  unitOfTime,
  onUnitChange,
}: PremiumPriceTagProps): JSX.Element {
  return (
    <div>
      <div aria-live="polite" className="text-lg font-semibold mb-2">
        <span className="text-4xl">
          {unitOfTime === 'month' ? '$5' : '$4'}
        </span>{' '}
        /monthly
      </div>

      <div className="text-xs font-semibold flex items-center gap-1 uppercase text-white text-opacity-60">
        <div>Monthly</div>
        <Switch
          aria-label="Switch from monthly to yearly"
          checked={unitOfTime === 'year'}
          onChange={(checked) => onUnitChange(checked ? 'year' : 'month')}
        />
        <div>Yearly</div>
      </div>
    </div>
  )
    */}
    function PremiumPriceTag({
      unitOfTime,
      onUnitChange,
    }: PremiumPriceTagProps): JSX.Element {
      return (
        <div>
          <div aria-live="polite" className="text-lg font-semibold mb-2">
            <span className="text-4xl">
              {unitOfTime === 'month' ? '$10' : '$10'}
            </span>{' '}
            /monthly
          </div>
    {/*
          <div className="text-xs font-semibold flex items-center gap-1 uppercase text-white text-opacity-60">
            <div>Monthly</div>
            <Switch
              aria-label="Switch from monthly to yearly"
              checked={unitOfTime === 'year'}
              onChange={(checked) => onUnitChange(checked ? 'year' : 'month')}
            />
            <div>Yearly</div>
          </div>
          */}
        </div>
        
      )
}
