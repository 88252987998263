import { withDomain } from './withDomain'

export const WEB_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:1701'
    : process.env.FLAVOR
    ? `https://${process.env.FLAVOR}.thesukha.co`
    : `https://${
        process.env.CENTERED_BASE_URL ||
        process.env.NEXT_PUBLIC_CENTERED_BASE_URL
      }`

export const baseDomain = (): string => WEB_URL

export function withBaseDomain(url: string): string {
  if (url.startsWith('http')) {
    return url
  }

  return withDomain(baseDomain(), url)
}
