import Image from 'next/image'
import TryCenteredButton from './TryCenteredButton'
import Background from './assets/Screenplay-darken-bg.png'

export default function LaserFocus(): JSX.Element {
  return (
    <div className="relative w-full py-36 bg-black">
      <Image
        src={Background}
        fill
        role="presentation"
        alt=""
        className="object-cover object-center"
      />

      <div className="text-center relative z-20 px-4 text-white">
        <h2 className="font-medium text-4xl mb-4">Why did we create this?</h2>
        <p className="text-2xl mb-14">
          <br />
          When I was a film executive at Fox and DreamWorks, I read scripts
          in a Starbucks on Sunset Blvd.  <br />
          <br />
          It was famous for the mix of young and experienced screenwriters <br />
          sitting table next to table...working away quietly.<br />
          <br />
          There was very little chatter...<br />just the sense of being part of a community trying
          to write the next great thing.<br />
          <br />
          This sense of "belonging" in that coffee shop was inspiring.<br />
          <br />
          And I want you to have that experience, too.<br />
          <br />
          - Steven Puri, Founder & CEO
        </p>

      </div>
    </div>
  )
}
