import Head from 'next/head'
import { withBaseDomain } from '../../util/baseDomain'

type Props = {
  title: string
  description?: string
  /** Relative URL of the page to share. */
  url?: string
  /** Relative URL of the open-graph image. */
  imageUrl?: string
}

const SEOMetaTags: React.FunctionComponent<Props> = ({
  title,
  description = 'Finish your work 2x as fast and feel relaxed with the #1 online co-working community. Sukha offers focus music, timers, coaches and more while you work.',
  url = '/',
  imageUrl = 'og-image.png',
}) => {
  return (
    <Head>
<meta name="keywords" content="Online co-working community,Focus music for work,AI productivity coach,Pomodoro technique app,Task management tools,
Meeting notifications app,Mindfulness at work,Stress-free work environment,Remote work productivity,
Time management apps,Work-life balance tips,Boost work efficiency,Virtual office space,
Collaborative work platform,Professional development apps,Achieve work goals,Increase work focus,
Mindful working techniques,Optimize work schedule,Best apps for professionals, The Sukha, Sukha, Centered, Centered.app" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="description" content={description} />
      <meta property="og:type" content="website" key="ogtype" />
      <meta property="og:url" content={withBaseDomain(url)} key="ogurl" />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:description"
        content={description}
        key="ogdescription"
      />
      <meta
        name="image"
        property="og:image"
        content={withBaseDomain(imageUrl)}
        key="ogimage"
      />

      <meta
        property="twitter:card"
        content="summary_large_image"
        key="twittercard"
      />
      <meta
        property="twitter:url"
        content={withBaseDomain(url)}
        key="twitterurl"
      />
      <meta property="twitter:title" content={title} key="twittertitle" />
      <meta
        property="twitter:description"
        content={description}
        key="twitterdescription"
      />
      <meta
        property="twitter:image"
        content={withBaseDomain(imageUrl)}
        key="twitterimage"
      />
    </Head>
  )
}

export default SEOMetaTags
