import Image from 'next/image'
import { Quote } from './quotes'

interface QuoteModuleProps {
  quote: Quote
  reverse?: boolean
}

export default function QuoteModule({
  quote,
  reverse = false,
}: QuoteModuleProps): JSX.Element {
  return (
    <div className="relative py-14 md:py-28 px-8">
      <Image
        src={quote.background}
        fill
        alt=""
        role="presentation"
        className="object-cover object-center"
      />

      <div
        className={`flex flex-col-reverse ${
          reverse ? 'md:flex-row-reverse' : 'md:flex-row'
        } gap-12 mx-auto w-full max-w-4xl relative text-black items-center`}
      >
        <div className="flex flex-col items-center flex-shrink-0">
          <Image
            src={quote.avatar}
            alt={quote.name}
            width={140}
            height={140}
            className="rounded-full mb-2"
          />

          <p className="text-2xl font-bold mb-1">{quote.name}</p>

          <p className="font-semibold italic text-black">{quote.handle}</p>
        </div>

        <div className="relative pt-4 flex-1">
          <p className="text-v5-red text-7xl leading-none absolute -top-10">
            “
          </p>

          <p className="text-2xl md:text-3xl leading-normal md:leading-normal font-semibold italic">
            {quote.quote}
          </p>
        </div>
      </div>
    </div>
  )
}
