"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function LinearLogo(_a) {
    var circular = _a.circular, className = _a.className;
    return (React.createElement("div", { className: className }, circular ? (React.createElement("svg", { "aria-label": "Linear logo", role: "img", width: "1em", height: "1em", viewBox: "0 0 17 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M7.08103 16.9061L0.10376 9.92456C0.699773 13.4932 3.51469 16.3095 7.08103 16.9061Z", fill: "url(#paint0_linear_2:63)" }),
        React.createElement("path", { d: "M0 8.03758L8.96694 17.0099C9.49903 16.9808 10.0181 16.9025 10.5194 16.7801L0.229789 6.48425C0.107251 6.9861 0.0291848 7.50518 0 8.03758Z", fill: "url(#paint1_linear_2:63)" }),
        React.createElement("path", { d: "M0.677124 5.1484L11.8549 16.3327C12.2616 16.1575 12.6516 15.9515 13.0226 15.7178L1.29172 3.9801C1.05802 4.3512 0.852177 4.7416 0.677124 5.1484Z", fill: "url(#paint2_linear_2:63)" }),
        React.createElement("path", { d: "M2.04944 2.95518C3.60935 1.1455 5.91783 0 8.49342 0C13.1916 0 17 3.81069 17 8.51143C17 11.0888 15.8551 13.3986 14.0466 14.9594L2.04944 2.95518Z", fill: "url(#paint3_linear_2:63)" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_2:63", x1: "17", y1: "22.8283", x2: "17", y2: "-4.90156e-05", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#5C65C2" }),
                React.createElement("stop", { offset: "0.848958", stopColor: "#5C65C2" }),
                React.createElement("stop", { offset: "1", stopColor: "#5C65C2" })),
            React.createElement("linearGradient", { id: "paint1_linear_2:63", x1: "17", y1: "22.8283", x2: "17", y2: "-1.48734e-05", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#5C65C2" }),
                React.createElement("stop", { offset: "0.848958", stopColor: "#5C65C2" }),
                React.createElement("stop", { offset: "1", stopColor: "#5C65C2" })),
            React.createElement("linearGradient", { id: "paint2_linear_2:63", x1: "17.0002", y1: "22.8284", x2: "17.0002", y2: "5.7457e-05", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#5C65C2" }),
                React.createElement("stop", { offset: "0.848958", stopColor: "#5C65C2" }),
                React.createElement("stop", { offset: "1", stopColor: "white" }),
                React.createElement("stop", { offset: "1" })),
            React.createElement("linearGradient", { id: "paint3_linear_2:63", x1: "17", y1: "22.8284", x2: "17", y2: "0", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#5C65C2" }),
                React.createElement("stop", { offset: "0.848958", stopColor: "#5C65C2" }),
                React.createElement("stop", { offset: "1", stopColor: "#5C65C2" }))))) : (React.createElement("svg", { "aria-label": "Linear logo", role: "img", viewBox: "0 0 52 52", width: "1em", height: "1em", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M39.557 0H12.413C5.558 0 0 5.56 0 12.42v27.16C0 46.44 5.558 52 12.413 52h27.144c6.855 0 12.413-5.56 12.413-12.42V12.42C51.97 5.56 46.412 0 39.557 0z", fill: "url(#prefix__paint0_linear)" }),
        React.createElement("path", { d: "M23.404 41.279l-12.69-12.698c1.084 6.49 6.204 11.613 12.69 12.698z", fill: "url(#prefix__paint1_linear)" }),
        React.createElement("path", { d: "M10.525 25.15l16.309 16.318a15.465 15.465 0 002.823-.418L10.944 22.324a15.47 15.47 0 00-.418 2.825z", fill: "url(#prefix__paint2_linear)" }),
        React.createElement("path", { d: "M11.756 19.895l20.33 20.341c.74-.319 1.449-.693 2.124-1.118L12.874 17.77c-.425.675-.8 1.385-1.118 2.125z", fill: "url(#prefix__paint3_linear)" }),
        React.createElement("path", { d: "M14.253 15.906a15.432 15.432 0 0111.72-5.375c8.544 0 15.471 6.93 15.471 15.48a15.45 15.45 0 01-5.371 11.727l-21.82-21.832z", fill: "url(#prefix__paint4_linear)" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "prefix__paint0_linear", x1: 0, y1: 0, x2: 0, y2: 52, gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#636DCE" }),
                React.createElement("stop", { offset: 0.984, stopColor: "#2C3480" })),
            React.createElement("linearGradient", { id: "prefix__paint1_linear", x1: 41.444, y1: 52.05, x2: 41.444, y2: 10.531, gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#D6D9FF" }),
                React.createElement("stop", { offset: 0.849, stopColor: "#FCFCFF" }),
                React.createElement("stop", { offset: 1, stopColor: "#fff" })),
            React.createElement("linearGradient", { id: "prefix__paint2_linear", x1: 41.444, y1: 52.05, x2: 41.444, y2: 10.531, gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#D6D9FF" }),
                React.createElement("stop", { offset: 0.849, stopColor: "#FCFCFF" }),
                React.createElement("stop", { offset: 1, stopColor: "#fff" })),
            React.createElement("linearGradient", { id: "prefix__paint3_linear", x1: 41.444, y1: 52.05, x2: 41.444, y2: 10.531, gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#D6D9FF" }),
                React.createElement("stop", { offset: 0.849, stopColor: "#FCFCFF" }),
                React.createElement("stop", { offset: 1, stopColor: "#fff" })),
            React.createElement("linearGradient", { id: "prefix__paint4_linear", x1: 41.444, y1: 52.05, x2: 41.444, y2: 10.531, gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#D6D9FF" }),
                React.createElement("stop", { offset: 0.849, stopColor: "#FCFCFF" }),
                React.createElement("stop", { offset: 1, stopColor: "#fff" })))))));
}
exports.default = LinearLogo;
