import Image from 'next/image'
import TryCenteredButton from './TryCenteredButton'
import Background from './assets/laser-focus-background.png'

export default function LaserFocus(): JSX.Element {
  return (
    <div className="relative w-full py-36 bg-black">
      <Image
        src={Background}
        fill
        role="presentation"
        alt=""
        className="object-cover object-center"
      />

      <div className="text-center relative z-20 px-4 text-white">
        <h2 className="font-medium text-4xl mb-4">Laser Focus Awaits</h2>
        <p className="mb-14">
          Get started <b>for free</b> and learn how quickly you’ll get your work
          done with Sukha.
        </p>

        <TryCenteredButton
          source="v5CTALaser"
          noGoogleAuthSource="v5CTALaserNonGoogle"
        />
        <p className="text-xl leading-normal md:mr-8 mb-8 mt-10">
            <i>Claim your 14-Day Free Trial - no credit card required.</i>
          </p>
      </div>
    </div>
  )
}
