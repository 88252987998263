"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var AsanaLogo = function (_a) {
    var className = _a.className;
    return (React.createElement("div", { className: className },
        React.createElement("svg", { "aria-label": "Asana logo", role: "img", height: "1em", viewBox: "0 0 16 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.52 7.84a3.482 3.482 0 00-3.48 3.483 3.482 3.482 0 003.48 3.484c1.922 0 3.48-1.56 3.48-3.484a3.482 3.482 0 00-3.48-3.484zm-9.04 0A3.482 3.482 0 000 11.322a3.482 3.482 0 003.48 3.484c1.922 0 3.48-1.56 3.48-3.484A3.482 3.482 0 003.48 7.84zm8-4.356A3.482 3.482 0 018 6.968a3.482 3.482 0 01-3.48-3.484A3.482 3.482 0 018 0c1.922 0 3.48 1.56 3.48 3.484z", fill: "url(#prefix__paint0_radial)" }),
            React.createElement("defs", null,
                React.createElement("radialGradient", { id: "prefix__paint0_radial", cx: 0, cy: 0, r: 1, gradientUnits: "userSpaceOnUse", gradientTransform: "matrix(10.608 0 0 9.81734 8.002 8.102)" },
                    React.createElement("stop", { stopColor: "#FFB900" }),
                    React.createElement("stop", { offset: 0.6, stopColor: "#F95D8F" }),
                    React.createElement("stop", { offset: 0.999, stopColor: "#F95353" }))))));
};
exports.default = AsanaLogo;
